<template>
  <NuxtLayout name="search-basic-layout">
    <div class="overflow-y-hidden">
      <SearchBarWrapper></SearchBarWrapper>
      <SpaceBannerWrapper></SpaceBannerWrapper>
      <!--      <div class="h-[40px]"></div>
            <SpaceTypeListWrapper></SpaceTypeListWrapper>-->
      <client-only>
        <SpaceListDisplayWrapper class="my-[50px]"
                                 :title="$t('sub_title.new_space')"
                                 :sub-title="$t('sub_title.new_space_description')"
                                 :iconName="'new'"
                                 :space-card-list="newSpaceList"
                                 :is-more="true"
                                 :more-search-param="newSpaceListQueryData"></SpaceListDisplayWrapper>
      </client-only>
      <SpaceListDisplayWrapper class="my-[50px]"
                               :title="$t('sub_title.trend_space')"
                               :sub-title="$t('sub_title.trend_space_description')"
                               :iconName="'hot'"
                               :space-card-list="trendSpaceList"></SpaceListDisplayWrapper>
      <LocationSearchWrapper class="mt-[50px] mb-[40px] mobile:mt-[40px]"></LocationSearchWrapper>
      <hr class="mx-auto pc:w-[calc(1366px-140px)]">
      <UsageSearchWrapper class="mt-[50px] mb-[40px]"></UsageSearchWrapper>
      <hr class="mx-auto pc:w-[calc(1366px-140px)]">

      <SpaceListDisplayWrapper v-if="like268SpaceList.length>0"
                               class="my-[50px]"
                               :title="'萊客共享空間'"
                               :sub-title="'台北車站場地租借'"
                               :iconName="'hot'"
                               :space-card-list="like268SpaceList"
                               :is-more="true"
                               :more-search-param="like268SpaceListQueryData"></SpaceListDisplayWrapper>

      <SpaceListDisplayWrapper v-if="richerDaySpaceList.length>0"
                               class="my-[50px]"
                               :title="'日富空間'"
                               :sub-title="'台北車站場地租借'"
                               :iconName="'hot'"
                               :space-card-list="richerDaySpaceList"
                               :is-more="true"
                               :more-search-param="richerDaySpaceListQueryData"></SpaceListDisplayWrapper>

      <BlogArticleWrapper></BlogArticleWrapper>
      <OurFeaturesWrapper></OurFeaturesWrapper>
      <HowToReserveWrapper></HowToReserveWrapper>
      <LazyFooterLoginWrapper></LazyFooterLoginWrapper>
    </div>
  </NuxtLayout>
</template>
<script setup>
definePageMeta(
    {title: '空間便利店'}
)

import SearchBarWrapper from "@/components/SearchIndex/SearchBarWrapper/SearchBarWrapper.vue";
// import LazyFooterLoginWrapper from "@/components/SearchIndex/FooterLogin/FooterLoginWrapper.vue"
const LazyFooterLoginWrapper = defineAsyncComponent(() =>
    import('@/components/SearchIndex/FooterLogin/FooterLoginWrapper.vue')
);
import LocationSearchWrapper from "@/components/SearchIndex/FastSearch/LocationSearchWrapper.vue"
import UsageSearchWrapper from "@/components/SearchIndex/FastSearch/UsageSearchWrapper.vue"
import SpaceListDisplayWrapper from "@/components/SearchIndex/SearchSpaceCard/SpaceListDisplayWrapper.vue"
import OurFeaturesWrapper from "../components/SearchIndex/OurFeatures/OurFeaturesWrapper.vue";
import HowToReserveWrapper from "../components/SearchIndex/HowToReserve/HowToReserveWrapper.vue";
// import SpaceTypeListWrapper from "@/components/SearchIndex/SpaceTypeList/SpaceTypeListWrapper.vue";
import SpaceBannerWrapper from "@/components/SearchIndex/SpaceBanner/SpaceBannerWrapper.vue";
import BlogArticleWrapper from "@/components/SearchIndex/BlogArticle/BlogArticleWrapper.vue";

import {getElementByDate} from "@/utils/parseTool.js"
import {useSettingStore} from "@/stores/setting";
import {
  newSpaceListQueryData,
  trendSpaceListQueryDataList,
  richerDaySpaceListQueryData,
  trendSpaceListQueryDataList2,
  like268SpaceListQueryData
} from "@/config/testCode.js"
import {useSearch} from "@/composables/useSearch";


const settingStore = useSettingStore()
const {handleSearch} = useSearch()

// 使用 useAsyncData 來抓取 newSpaceListQueryData 資料
const {data: newSpaceListData} = await useAsyncData(
    'newSpaceListQueryData',
    () => handleSearch(newSpaceListQueryData));

/*const newSpaceList = computed(() => {
  return newSpaceListData.value ?
      newSpaceListData.value.list
          .sort(() => Math.random() - 0.5)
          .slice(0, 4) :
      [];
});*/
const newSpaceList = ref([]); // 新的空間


// 熱門空間 使用 useAsyncData 來抓取 trendSpaceListQueryDataList 資料
/*const {data: trendSpaceListData} = await useAsyncData(
    'trendSpaceListQueryDataList',
    () => handleSearch(getElementByDate(trendSpaceListQueryDataList)));

const trendSpaceList = computed(() => {
  return trendSpaceListData.value ? trendSpaceListData.value.list : [];
});*/

// 新的熱門空間
const {data: trendSpaceListData} = await useAsyncData(
    'trendSpaceListQueryDataList',
    async () => {
      // 使用 Promise.all 並行呼叫每個查詢
      const responses = await Promise.all(
          trendSpaceListQueryDataList2.map(query => handleSearch(query))
      );

      // 提取每個回傳結果的第一筆資料
      return responses
          .filter(spaceList => spaceList.total > 0)
          .map(response => response.list?.[0] || {});
    }
);

// 將組合好的資料轉換為 computed
const trendSpaceList = computed(() => {
  return trendSpaceListData.value || [];
});


// 日富空間資料
const {data: richerDaySpaceListData} = await useAsyncData(
    'richerDaySpaceListQueryDataList',
    () => handleSearch(richerDaySpaceListQueryData));

const richerDaySpaceList = computed(() => {
  return richerDaySpaceListData.value ? richerDaySpaceListData.value.list.slice(0, 4) : [];
});

// 萊客共享空間
const {data: like268SpaceListData} = await useAsyncData(
    'like268SpaceListQueryDataList',
    () => handleSearch(like268SpaceListQueryData));

const like268SpaceList = computed(() => {
  return like268SpaceListData.value ? like268SpaceListData.value.list.slice(0, 4) : [];
});

// 如果資料非響應式，可以使用 useServerSeoMeta()，在 server 端預先處理完 meta 相關邏輯，提升網頁效能
const seoMeta = {
  title: '空間便利店｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  description: '空間便利店是一個線上的場地租借平台。我們提供線上預約，省去聯絡，方便又順利。活動空間、會議室，攝影棚、餐廳包廂、聚會場地都可預約',
  keywords: '場地租借、會議室、攝影棚、廚房租借、活動空間、餐廳包廂、聚會場地，都可線上預約',
  ogTitle: '空間便利店｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  ogSiteName: '空間便利店',
  ogDescription: '空間便利店是一個線上的場地租借平台！我們提供線上預約，省去聯絡，方便又順利。活動空間、會議室，攝影棚、餐廳包廂、聚會場地都可預約',
  ogImage: 'https://pic.onestep.place/images/onestep-og.png',
  ogImageAlt: '空間便利店｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  ogUrl: 'https://www.onestep.place',
  ogType: 'website',
};

useSeoMeta(seoMeta);

onMounted(() => {
  settingStore.updateHomeRouter('/')
  if (newSpaceListData.value && Array.isArray(newSpaceListData.value.list)) {
    newSpaceList.value = [...newSpaceListData.value.list]
        .sort(() => Math.random() - 0.5)
        .slice(0, 4);
  }
})

useHead({
  script: [
    {
      type: 'application/ld+json',
      children: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "空間便利店",
        "url": "https://www.onestep.place/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.onestep.place/search-result?_keyword={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      })
    }
  ]
})


</script>
<style scoped
       lang="scss">

</style>
