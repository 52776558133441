<template>
  <div class="our-features-card">
    <img :src="getImageUrl('icon/our_features',icon)" alt=""/>
    <div>{{ title }}</div>
    <div>{{ content }}</div>
  </div>
</template>

<script setup>
import { useImageUrl } from '~/composables/useImageUrl';
const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  },
})

const getImageUrl = useImageUrl;
// const iconSrc = computed(() => new URL(`../../../assets/image/icon/our_features/${props.icon}`, import.meta.url).href);

</script>

<style scoped lang="scss">
.our-features-card {
  @apply flex flex-col items-center w-[270px] h-[220px] bg-white rounded-[10px] mr-[14px] px-[18px]
  text-osn-dark-grey-00 text-center
  mobile:w-full mobile:mx-[16px] mobile:mb-[14px];
  img {
    @apply w-[60px] h-[60px] mt-[26px];
  }

  div:nth-child(2) {
    @apply text-[18px] font-bold mt-[8px] mb-[6px];
  }

  div:nth-child(3) {
    @apply text-[15px];
  }
}
</style>
