<template>
  <div class="text-[20px] font-bold">{{ title }}</div>
  <div class="flex flex-wrap min-w-[80px]">
    <div class="city-unit"
         @click="onSearch(item.id||item.city_id, item.name||item.city_name)"
         v-for="item in unitList">{{ item.name || item.city_name }}
    </div>
  </div>
</template>
<script setup>
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {useGoTo} from "@/composables/useGoTo";
import {useSearchWithStore} from "@/composables/useSearchWithStore";

const searchQueryStore = useSearchQueryStore()
const {go,goWithQuery} = useGoTo();
const {handleSearchWithStore} = useSearchWithStore()

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  unitList: {
    type: Array,
    default: []
  },
  searchStore: {
    type: Function
  }
})

function onSearch(id, name) {
  searchQueryStore.initQueryDate()
  props.searchStore([id])
  handleSearchWithStore()

  // go('/search-result')
  // todo 之後第二頁用途設計好後要去掉
  goWithQuery('/search-result', {categoryId: id,name:name})
}

</script>
<style scoped
       lang="scss">
div.city-unit {
  @apply mr-[5px] mb-[8px] px-[8px] py-[5px] border rounded-xl border-osn-grey-03 text-[16px] cursor-pointer;
}

</style>
