<template>
 <div class="search-bar-wrapper" :style="backgroundStyles">
   <h1 class="font-bold pc:text-[20px] mobile:text-[14px]">{{$t('menu.upper_title')}}</h1>
   <h2 class="my-2 pc:text-[36px] mobile:text-[24px]">{{$t('menu.title')}}</h2>
   <h3  class="mb-5 font-normal pc:text-[20px] mobile:text-[14px]">{{ $t('menu.sub_title')}}</h3>
   <SearchBar  :is-index="true"></SearchBar>
 </div>

</template>
<script setup>
import SearchBar from "@/components/SearchIndex/SearchBarWrapper/SearchBar.vue";


const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);
const img = useImage();

const backgroundStyles = computed(() => {
  const imgUrl = isMobile.value
      ?img('/background/searchBarM.png', { sizes: '100vw' ,  format: 'webp', quality: 80 })
      :img('/background/searchBar.png', { sizes: '100vw' ,  format: 'webp', quality: 80 })
  return { backgroundImage: `url('${imgUrl}')` }
})


</script>
<style scoped
       lang="scss">
.search-bar-wrapper{
  @apply mx-auto pt-[46px] h-[300px] text-center text-[32px] font-black bg-cover;
}
/*
!*1920 版本*!
@media (min-width: 1365px) {
  .search-bar-wrapper{
    background-image: url("@/assets/image/background/searchBar.png");
  }
}

!*1366 版本*!
@media (max-width: 1365px) {
  .search-bar-wrapper{
    background-image: url("@/assets/image/background/searchBar.png");
  }
}

!*390 版本*!
@media (max-width: 767px) {
  .search-bar-wrapper{
    background-image: url("@/assets/image/background/searchBarM.png");
  }
}
*/


</style>
