
import {getSearchSpaceList} from "@/api/space";

export function useSearch() {

    async function handleSearch(inQueryData) {
        if (!inQueryData) {
            return
        }

        try {
            const res = await getSearchSpaceList(inQueryData);
            /*
            沒空間的回傳
            {
                "request_id": "4f807f4c",
                "total": 0,
                "list": []
            }*/

            return {
                list: res.list,
                request_id: res.request_id,
                total: res.total
            }

        } catch (error) {
            console.log(error);
        }

    }


    return {
        handleSearch
    }
}
